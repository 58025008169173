import propOr from 'ramda/src/propOr';
import includes from 'ramda/src/includes';
import { useAuth } from './useAuth';

const getResourcesList = propOr([], 'resources');

export const useCan = () => {
  const auth = useAuth().getAuth();

  return (allow) => includes(allow, getResourcesList(auth));
};
